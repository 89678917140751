import * as React from "react"
import "../styles/main.scss"
import Layout from "../components/layout.js"

// markup
const IndexPage = () => {
  return (
    <main>
      <Layout>Home</Layout>
      <h2>What is Seed Cycle?</h2>
      <p>
        Seed Cycle is an RNG controlled rotating seed contest that challenges the Minecraft speedrunning community to route a new seed every 2 weeks
      </p>

      <h2>What are the rules?</h2>
      <p>
        Same rules as Minecraft: Java Edition on <a href="https://www.speedrun.com/mc" target="_blank" rel="noreferrer">speedrun.com/mc</a> with a few exceptions to speed up verification:
        <ul>
          <li>Time is based solely on IGT</li>
          <li>You must use SpeedrunIGT mod: <a href="https://redlime.github.io/SpeedRunIGT/" target="_blank" rel="noreferrer">download</a></li>
          <li>You must use Seed Cycle Mod v1.0.1: <a href="https://github.com/DuncanRuns/Seed-Cycle-Mod/releases/tag/v1.0.1" target="_blank" rel="noreferrer">download</a></li>
          <li>Runs must be submitted before the closing time on the leaderboard page.</li>
        </ul>
      </p>

      <h2>How do I submit my runs?</h2>
      <p>
        To make your submission you will need: Your SRC Username, Seed, IGT, <br />Video Link to Run, World File and then submit via the link below:
      </p>
      <a href="https://forms.gle/mazwTstwHBQLFXqN8" target="_blank" rel="noreferrer" className="submit">Submission Form</a>
      <p>
        For submission you need: Your SRC Username, Seed, IGT, Video Link to Run, World File
      </p>

      <h2>Season Winner Prize Pool</h2>
      <p>
        For the overall season prize pool at the end of the 6 cycles we have a small prize pool:<br />
        1st: $100, 2nd: $60, 3rd: $40
      </p>

      <h2>Point Distribution</h2>
      <p>
        Each cycle will have contribute towards your overall points. This is currently a work in progress and TBA
      </p>
    </main>
  )
}

export default IndexPage
